<template>
  <div class="page-content">
    <b-card>
      <b-row>
        <b-col sm="6" md="3" v-for="(step, i) in steps" :key="i" class="my-1">
          <b-button @click="currentStep = i" class="font-weight-normal text-left" variant="primary" block :disabled="i > currentStep" style="font-size: 14px">
            {{ step }}
          </b-button>
        </b-col>
      </b-row>

      <!-- STEP 1 -->
      <b-card no-body class="mt-4" v-if="currentStep == 0">
        <b-card-body>
          <b-card-title class="text-capitalize">Channel</b-card-title>
          <!-- <button @click="launchWhatsAppSignup()" style="background-color: #1877f2; border: 0; border-radius: 4px; color: #fff; cursor: pointer; font-family: Helvetica, Arial, sans-serif; font-size: 16px; font-weight: bold; height: 40px; padding: 0 24px;">Login with Facebook</button> -->
          <b-card-sub-title class="mb-4 ">
            Currently, we support WhatsApp as platforms. We have more platforms like Website live chat widgets, Facebook Pages, Twitter, Email, Telegram and Line in the works, which will be out soon.
          </b-card-sub-title>
          <b-row>
            <b-col v-for="(ch, i) in channelList" :key="i" sm="6" md="3" class="mb-4">
              <!-- <b-card no-body @click="setChannel('whatsapp')" style="cursor: pointer">
                <b-card-body style="background-color: #CCCCCC">
                  <h5>
                    <a href="#">
                      <span><mdicon name="whatsapp"/></span>
                    </a>
                  </h5>
                </b-card-body>
                <b-card-footer class="font-weight-bold">
                  Whatsapp
                </b-card-footer>
              </b-card> -->
              <div class="channel-card text-center" @click="setChannel(ch.value)" style="cursor: pointer">
                <h5>
                  <a href="#"><span :class="ch.value"><i :class="ch.icon"></i></span>{{ ch.name }}</a>
                </h5>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- STEP 2 -->
      <b-card no-body class="mt-4" v-else-if="currentStep == 1 && type == 'whatsapp'">
        <add-whatsapp @createChannel="createChannel"/>
      </b-card>
      <b-card no-body class="mt-4" v-else-if="currentStep == 1 && type == 'telegram'">
        <add-telegram @createChannel="createChannel"/>
      </b-card>
      <b-card no-body class="mt-4" v-else-if="currentStep == 1 && type == 'webchat'">
        <add-webchat @createChannel="createChannel"/>
      </b-card>

      <!-- STEP 3 -->
      <!-- <b-card no-body class="mt-4" v-else-if="currentStep == 2">
        <b-card-body>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th width="50">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input"><i class="input-frame"></i>
                      </label>
                    </div>
                  </th>
                  <th class="align-middle">Name</th>
                  <th class="align-middle">Email</th>
                </tr>
              </thead>
              <tbody>
                <tr width="50" v-for="(user, i) in userList" :key="i">
                  <td class="py-1">
                    <img src="http://via.placeholder.com/36x36" alt="image">
                  </td>
                  <td>{{ user.name }}</td>
                  <td>{{ user.email }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end mt-4">
            <b-button @click="currentStep--" variant="outline-primary" class="mr-1">Prev</b-button>
            <b-button @click="currentStep++" variant="primary">Next</b-button>
          </div>
        </b-card-body>
      </b-card> -->

      <!-- STEP 4 -->
      <b-card no-body class="mt-4" v-else-if="currentStep == 2">
        <b-row>
          <b-col sm="6" offset-sm="3">
            <p class="font-weight-bold text-center mt-4" style="font-size: 1.5rem">Your inbox is ready</p>
            <b-card-sub-title class="text-center" style="font-size: 14px">
              You have successfully finished creating a website channel. Copy the code shown below and paste it on your website. Next time a customer use the live chat, the conversation will automatically appear on your inbox.
            </b-card-sub-title>
            <div v-if="createChannelResult.type === 'webchat'" class="bd-example text-sm text-monospace mt-4" style="position: relative;">
              <el-popover
                placement="top"
                trigger="hover"
                style="position: absolute; top: 10px; right: 10px">
                <p style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all; font-size: 12px;">Copy to clipboard, paste inside <strong>&lt;script&gt;&lt;/script&gt;</strong> tag</p>
                <button slot="reference" type="button" class="btn btn-outline-primary btn-xs" @click="copyScriptEmbed">Copy</button>
              </el-popover>
              <pre id="code-script-embed" class="rounded" style="font-size: 12px">{{ script_embed_webchat_sdk }}</pre>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import channelsAPI from '../../../api/channels';
import usersAPI from '../../../api/users';
// import fbSdk from '../../../library/fb-sdk';
import popupErrorMessages from '../../../library/popup-error-messages';
import AddTelegram from '../../components/channels/AddTelegram.vue';
import AddWhatsapp from '../../components/channels/AddWhatsapp.vue';
import AddWebchat from '../../components/channels/AddWebchat.vue';

export default {
  components: {
    AddWhatsapp,
    AddTelegram,
    AddWebchat,
  },
  name: 'ChannelsAdd',
  metaInfo: {
    title: 'Add Channels',
  },
  data() {
    return {
      loaderStack: 0,
      loader: null,
      currentStep: 0,
      type: '',
      provider: '',
      steps: [
        '1. Choose Type',
        '2. Create Channel',
        // '3. Add Agents',
        '3. Voila!',
      ],
      whatsappProvider: [
        // { text: 'Wappin', value: 'makna' },
        { text: 'DAMCorp', value: 'damcorp' },
        { text: 'SPE', value: 'spe' },
        { text: 'On Premise', value: 'client_premise' },
      ],
      configuration: {
        username: 'admin',
        password: '',
        base_url: '',
        token: '',
        business_id: '',
        sender: '',
      },
      channelList: [
        { name: 'WhatsApp', icon: 'mdi mdi-whatsapp', value: 'whatsapp' },
        { name: 'Telegram', icon: 'mdi mdi-telegram', value: 'telegram' },
        { name: 'Email', icon: 'mdi mdi-email', value: 'email' },
        { name: 'Instagram', icon: 'mdi mdi-instagram', value: 'instagram' },
        { name: 'Webchat', icon: 'mdi mdi-forum-outline', value: 'webchat' },
      ],
      userList: [],
      isIndeterminate: false,
      createChannelResult: {
        configuration: {},
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    script_embed_webchat_sdk() {
      let script_embed = `(function(d,t) {
  localStorage.setItem("makna-webchat-sdk-api-url", "[sdk-api-url]");
  localStorage.setItem("makna-webchat-sdk-token", "[sdk-token]");
  var g=d.createElement(t),s=d.getElementsByTagName(t)[0],w = d.createElement('div');
  w.setAttribute("id", "makna-sdk");
  g.type="module";
  g.crossorigin="anonymous";
  g.src="[sdk-cdn-url]";
  g.defer = true;
  g.async = true;
  s.parentNode.insertBefore(w,s);
  s.parentNode.insertBefore(g,s);
})(document,"script");`;
      script_embed = script_embed.replace('[sdk-api-url]', (process.env.VUE_APP_WEBCHAT_SDK_API_URL || ''));
      script_embed = script_embed.replace('[sdk-token]', (this.createChannelResult.configuration.access_token || ''));
      script_embed = script_embed.replace('[sdk-cdn-url]', (process.env.VUE_APP_WEBCHAT_SDK_CDN_URL || ''));
      return script_embed;
    },
  },
  watch: {
    script_embed_webchat_sdk() {},
  },
  created() {
    // fbSdk.initFacebookSdk();
  },
  async mounted() {
    this.getUserList();
  },
  methods: {
    setChannel(type) {
      if (!['whatsapp', 'telegram', 'webchat'].includes(type)) {
        this.$message({
          message: 'Coming soon :)',
          type: 'info',
        });
        return;
      }
      this.type = type;
      this.currentStep = 1;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async createChannel(configuration, provider) {
      // console.log(configuration, provider);
      this.showLoader();
      const channelData = {
        type: this.type,
        provider,
        configuration,
      };
      // console.log(channelData);
      await channelsAPI.createChannel(this.activeWorkspace._id, channelData)
        .then(async (response) => {
          // console.log(response);
          await popupErrorMessages(response);
          if (response.data.channel) {
            this.createChannelResult = response.data.channel;
          }
          this.currentStep += 1;
        })
        .catch(() => {});
      this.hideLoader();
    },
    async getUserList() {
      await usersAPI.getList()
        .then(async (res) => {
          await popupErrorMessages(res);
          this.userList = res.data.rows;
        });
    },
    clickAllCheckbox() {
    },
    /* eslint-disable */
    // Facebook Login with JavaScript SDK
    launchWhatsAppSignup() {
      FB.login((response) => {
        console.log('response', response);
        if (response.authResponse) {
          const { accessToken } = response.authResponse;
          console.log(accessToken);
          // Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log('User cancelled login or did not fully authorize.', response);
        }
      }, {
        scope: 'business_management,whatsapp_business_management',
        extras: {
          feature: 'whatsapp_embedded_signup',
          setup: { // Prefilled data can go here
          },
        },
      });
    },
    copyScriptEmbed() {
      const code_script = document.getElementById('code-script-embed');
      navigator.clipboard.writeText(code_script.innerHTML);
      this.$message({
        message: 'Script copied!',
        type: 'success',
      });
    },
  },
};
</script>
<style>
/*---------------------------*/
.channel-card{
  padding: 20px;
  box-shadow: 0 4px 9px rgba(0,0,0,0.08);
  margin-top: 35px;
  padding-top: 55px;
  position: relative;
  border: 1px solid #f4f4f4;
}
.channel-card a{
  color: #333;
}
.channel-card span{
  width: 70px;
  height: 70px;
  font-size: 36px;
  padding: 10px;
  /* background: #333; */
  color: #fff;
  text-align: center;
  padding-top: 12px;
  position: absolute;
  left: 50%;
  border-radius: 8px;
  top: 0;
  transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
}
</style>
