<template>
  <b-card-body>
    <template v-if="!choose_phone_number">
      <b-card-title>WhatsApp Business API</b-card-title>
      <b-card-sub-title>WhatsApp is one of the most popular communication applications in the world. WhatsApp is a Facebook company and requires a Facebook account and Facebook Business Manager to set up.</b-card-sub-title>
      <b-card-sub-title>Do you have WhatsApp registered with WhatsApp BSP?</b-card-sub-title>
      <b-form-group>
        <b-form-radio-group v-model="hasRegisteredAccount" :options="hasRegistered" buttons button-variant="outline-primary" />
      </b-form-group>
      <template v-if="hasRegisteredAccount != null && hasRegisteredAccount">
        <el-divider/>
        <b-form-group>
          <b-card-sub-title>We assume your WhatsApp are deployed on your server or BSP server.</b-card-sub-title>
          <b-form-radio-group v-model="provider" :options="whatsappProvider" buttons button-variant="outline-primary" />
          <!-- <b-card-sub-title>Or, you can use WhatsApp Cloud API</b-card-sub-title>
          <el-button :loading="loading.embed_signup" @click="launchWhatsAppSignup()" style="background-color: #1877f2; border: 0; border-radius: 4px; color: #fff; cursor: pointer; font-size: 16px; font-weight: bold; height: 40px; padding: 0 24px;">Get Started</el-button> -->
        </b-form-group>
      </template>
      <template v-if="hasRegisteredAccount != null && !hasRegisteredAccount">
        <el-divider/>
        <b-card-sub-title class="font-weight-bold">Before you start, you'll need:</b-card-sub-title>
        <ol>
          <li>Access to Facebook Business Manager</li>
          <li>A viable Phone Number for WhatsApp</li>
          <li>Your business legal address and details</li>
          <li>Select the numbers you want to connect</li>
          <li>Verify your Facebook Business</li>
        </ol>
        <el-button :loading="loading.embed_signup" @click="launchWhatsAppSignup()" style="background-color: #1877f2; border: 0; border-radius: 4px; color: #fff; cursor: pointer; font-size: 16px; font-weight: bold; height: 40px; padding: 0 24px;">Get Started</el-button>
      </template>
      <template v-if="hasRegisteredAccount != null && hasRegisteredAccount">
        <div v-if="['makna', 'client_premise'].includes(provider)">
          <b-form @submit.prevent="createChannel">
            <b-form-group label="WABA API Username:" label-for="wa-makna-username">
              <b-form-input autocomplete="off" placeholder="WABA API Username must admin" id="wa-makna-username" v-model="configuration.username" type="text" required />
            </b-form-group>
            <b-form-group label="WABA API Password:" label-for="wa-makna-password">
              <b-form-input autocomplete="one-time-code" placeholder="WABA API Password" id="wa-makna-password" v-model="configuration.password" type="password" required />
            </b-form-group>
            <b-form-group label="WABA API Base Url:" label-for="wa-makna-base-url">
              <b-form-input placeholder="WABA API Endpoint. eg: https://mywaba.mydomain.co.id" id="wa-makna-base-url" v-model="configuration.base_url" type="text" required />
            </b-form-group>
            <b-form-group label="WABA ID:" label-for="wa-makna-id">
              <b-form-input placeholder="Your WABA business ID. Ask your provider" id="wa-makna-waba-id" v-model="configuration.business_id" type="text" required />
            </b-form-group>

            <b-button type="submit" variant="primary">{{ $t('general.submit') }}</b-button>
          </b-form>
        </div>
        <div v-else-if="provider == 'damcorp'">
          <b-form @submit.prevent="createChannel">
            <b-form-group label="Token:" label-for="wa-damcorp-token">
              <b-form-textarea placeholder="Insert your token" id="wa-damcorp-token" v-model="configuration.token" rows="3" />
            </b-form-group>
            <b-button type="submit" variant="primary">{{ $t('general.submit') }}</b-button>
          </b-form>
        </div>
        <div v-else-if="provider == 'cloud_api_wa'">
          <b-form @submit.prevent="createChannel">
            <b-form-group label="Token:">
              <b-form-input placeholder="Insert your token" id="cloud-token" v-model="configuration.token"/>
            </b-form-group>
            <b-form-group label="Phone Number ID:">
              <b-form-input placeholder="Insert your phone number ID" id="cloud-phone-id" v-model="configuration.phone_number_id" />
            </b-form-group>
            <b-form-group label="Business ID:">
              <b-form-input placeholder="Insert your business ID" id="cloud-business-id" v-model="configuration.business_id"/>
            </b-form-group>
            <b-form-group label="Phone number:">
              <b-form-input placeholder="Insert your phone number, eg: 6281234567890, 12562967185" id="cloud-sender" v-model="configuration.sender"/>
            </b-form-group>
            <b-form-group label="Webhook Verify Token:">
              <b-form-input placeholder="Insert your webhook verify token" id="cloud-sender" v-model="configuration.verify_token"/>
            </b-form-group>
            <b-button type="submit" variant="primary">{{ $t('general.submit') }}</b-button>
          </b-form>
        </div>
      </template>
    </template>
    <template v-else>
      <b-card-title>Select Phone Number</b-card-title>
      <b-card-sub-title>Please select one of phone number below to be added to this workspace.</b-card-sub-title>
      <div v-for="(bus, index1) in phone_number_list" :key="index1">
        <p class="mb-2"><strong>{{ bus.business.name }}</strong><br>WABA ID {{ bus.business.id }}</p>
        <el-radio-group v-model="configuration.phone_number_id">
          <el-radio-button v-for="(phone, index2) in bus.phone_numbers" :key="index2" :label="phone.id" border>
            <span><country-flag :country="getCountryCode(phone.display_phone_number)" size='small'/>&nbsp;{{ phone.display_phone_number }}</span>
          </el-radio-button>
        </el-radio-group>
        <el-divider/>
      </div>
      <b-button @click="createChannel" variant="primary">{{ $t('general.submit') }}</b-button>
    </template>
  </b-card-body>
</template>
<script>
import { AsYouType } from 'libphonenumber-js';
import CountryFlag from 'vue-country-flag';
import channelsAPI from '../../../api/channels';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  components: {
    CountryFlag,
  },
  name: 'AddWhatsappChannel',
  data() {
    return {
      provider: 'client_premise',
      hasRegisteredAccount: null,
      whatsappProvider: [
        // { text: 'Makna.ai', value: 'makna' },
        // { text: 'DAMCorp', value: 'damcorp' },
        { text: 'On Premise', value: 'client_premise' },
        { text: 'Cloud API', value: 'cloud_api_wa' },
      ],
      hasRegistered: [
        { text: 'Yes, I have one', value: true },
        { text: 'No', value: false },
      ],
      configuration: {
        username: 'admin',
        password: '',
        base_url: '',
        token: '',
        business_id: '',
        phone_number_id: '',
      },
      loading: {
        embed_signup: false,
      },
      choose_phone_number: false,
      phone_number_list: [],
    };
  },
  methods: {
    getCountryCode(input) {
      const asYouType = new AsYouType();
      asYouType.input(input);
      return asYouType.getNumber().country.toLowerCase();
    },
    createChannel() {
      if (this.provider === 'cloud_api_embed') {
        const obj = this.phone_number_list.find((v) => {
          const o = v.phone_numbers.find((p) => p.id === this.configuration.phone_number_id);
          if (!o) {
            return false;
          }
          return v;
        });
        if (!obj) {
          return;
        }
        this.configuration.business_id = obj.business.id;
      }
      if (this.configuration.sender) {
        this.configuration.sender = this.configuration.sender.replace(/\D/ig, '');
      }
      this.$emit('createChannel', this.configuration, this.provider);
    },
    /* eslint-disable no-undef */
    async launchWhatsAppSignup() {
      try {
        this.loading.embed_signup = true;
        FB.login((response) => {
          if (response.authResponse) {
            // Use this token to call the debug_token API and get the shared WABA's ID
            const { accessToken } = response.authResponse;
            channelsAPI.initLinkAge({
              token: accessToken,
            }).then(async (response1) => {
              await popupErrorMessages(response1);
              const { data } = await channelsAPI.getEmbedPhoneNumber();
              this.phone_number_list = data.list;
              this.choose_phone_number = true;
              this.provider = 'cloud_api_embed';
            });
          } else {
            console.log('User cancelled login or did not fully authorize.', response);
          }
          this.loading.embed_signup = false;
        }, {
          scope: 'whatsapp_business_management,whatsapp_business_messaging',
          extras: {
            feature: 'whatsapp_embedded_signup',
            setup: {},
          },
        });
      } catch (error) {
        console.log(error);
        this.loading.embed_signup = false;
      }
    },
  },
};
</script>
